/**
 * Ushahidi Angular Icon Pocker directive
 * Based on the Angular Bootstrap Icon Picker directive
 */

module.exports = [
    'IconManager',
function (
    IconManager
) {
    return {
        restrict: 'E',
        templateUrl: 'templates/iconpicker/iconpicker.html',
        replace: true,
        scope: {
            iconContainer: '='
        },
        link: function ($scope, $element, $attrs) {
            $scope.icons = [
              'blue-economy',
              'building-capacity',
              'climate-ocean',
              'mapping-and-understanding',
              'marine-pollution',
              'marine-protected-areas',
              'our-ocean-actions',
              'our-ocean-conferences',
              'son-detection',
              'son-enforcement',
              'son-prosecution',
              'supporting-coastal-communities',
              'sustainable-fisheries'
            ];

            $scope.setIcon = function (icon) {
                $scope.iconContainer.icon = icon;
            };

            $scope.getPath = function (icon) {
                return '../../img/1000ocean/' + icon + '.svg#' + icon;
            };
        }
    };
}];

module.exports = [
function (
) {

    return {
        restrict: 'E',
        replace: true,
        scope: {
            view: '='
        },
        templateUrl: 'templates/posts/views/post-view-unavailable.html'
    };
}];
